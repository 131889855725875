<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>
       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>Parent Connect: Craft Engaging Parent Letters
        
        </v-card-title>
        <v-card-text>

          <v-textarea
           :rules="[rules.required]"
             v-model="letterTopics"
            outlined
            label="Please tell briefly about what you want to communicate "
          >
          </v-textarea>
<v-combobox
           
            append-icon="mdi-filter"
            v-model="tone"
            :items="['Funny','Friendly', 'Cold', 'Neutral','Official', 'Legal']"
            label="Tone"
            outlined
            clearable
             @input.native="tone=$event.srcElement.value"
          />
        </v-card-text>
         
        <v-card-actions>
          <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>

          <v-btn
            :disabled="!letterTopics"
            color="#212121"
            @click="sendRequest()"
          >
            
            <h3  style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
  
       </template>
     
   <v-snackbar
          v-model="showSnackbar"
         :timeout="6000"
           color="warning"
          top
        >
          {{ snackbarMessege }}
        </v-snackbar>
</base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";
export default {
 
  data() {
    return {
      what: "Parent Letter",
      tone: null,
      isLoading: false,
      letterTopics: null,   
      showSnackbar:false,
      snackbarMessege:"",
      rules: {
        required: (value) => !!value || "Required.",
      },
      
    };
  },
//   computed:{
//      premium(){
//       return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
//       || this.$store.state.subscription.status == 'active school'
//        || this.$store.state.subscription.status == 'trialing'
//       : false
//     }
//   },
  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
        let prompt = " can you write a parent letter for me about  "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
       
        prompt = " I am a teacher and I need help with this simple task, and I need this in rich text format enhaced with html tags, you can add emoji when appropriate "+prompt


let uid = firebaseAuth.currentUser.uid;

let newresource ={
    what: this.what,
    lastsaved: Date.now(),
    promt: [{role: "system",content: "You are an excellent educator, teacher and communicator."},
          {role: "user", content:prompt},
        ],

        uid:uid
        
}
 
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}


    },
//     async sendRequest_backup() {
//       this.isLoading = true;
     
//         let prompt =""
//         if(this.what =='Parent Letter' ){
//         prompt = " can you write a parent letter for me about  "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
//         }else if(this.what =='Translate' ){
//         prompt = " can you translate this text to "+this.language + " : " +this.inputText
//         }else if(this.what =='Write an Email' ){
//         prompt = "can you write and email for me with these points: "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
//         }else if(this.what =='Respond to an Email' ){
//         prompt = 'can you write a respond this email: '+this.inputText +". Please use these points in your response: "+ this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
//         }else if(this.what =='Tweet' ){
//         prompt = "can you write a Tweet with these points: "+ this.letterTopics 
//         }else if(this.what =='Recommendation Letter' ){
//         prompt = " one of my students need a recommendation letter from me. Can you write a Recommendation Letter with this info: "+ this.letterTopics 
//         }
// prompt = " I am a teacher and I need help with this simple task, and I need this in rich text format enhaced with html tags so that I can copy paste it to my website."+prompt
         
// let newresource ={
//     what: this.what,
//         topic: this.topic,
//         grade: this.grade,
//         lastsaved: Date.now(),
        
// }
// const functions = getFunctions();

//       try {
//       const functionRef = httpsCallable(
//         functions,
//         "callopenai"
//       );
//       const { data } = await functionRef({
//         promt: prompt,
//       });
 
//  newresource.resource = data.result
//  this.$store.commit("updateedited", newresource);
// this.isLoading = false;
     
//       this.$router.push('/editor'); 
// }catch (error) {
//       this.isLoading = false;
    
//      this.$router.push('/editor'); 
//       this.showSnackbar = true
//       this.snackbarMessege = "The system is overloaded, please try again" 
// }


//     },
    copy() {
      navigator.clipboard.writeText(this.receivedPlan);
    },
    
    clearSelections() {
      (this.topic = null),
        (this.grade = null),
        (this.genre = null),
        (this.tone = null),
        (this.characters = null),
        (this.voice = null),
        (this.letterTopics = null),
        (this.language = null),
        (this.quizType = null),
        (this.quizItemCount = null),
        (this.imgcheck = false),
        (this.learningStandards = null);
    },
     
  },
  
};
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
    
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
     
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    
    width: 800px;
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




